import {
	getUserInfoStorage,
	getStorage
} from '../service/storage.js'

export const formatNumber = (n) => {
	const str = n.toString()
	return str[1] ? str : `0${str}`
}
// 过滤表情
export const filterEmoji = (name) => {
	const str = name.replace(
		/[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig,
		"");
	return str;
}
export const filterSpace = (name) => {

	const str = name.replace(/\s+/g, '');

	return str;

}
// 简陋版屏蔽二次点击事件重复触发
export const debounceEvent = (fn, wait = 500) => {
	let timer = null
	return function() {
		var context = this
		var args = arguments
		if (timer) return
		fn.apply(context, args);
		timer = setTimeout(() => {
			timer = null
			clearTimeout(timer)
		}, wait)
	}
}
// 定义一个深拷贝函数  接收目标target参数
export const deepClone = (source) => {
	// if (!isObject(source)) return source; //如果不是对象的话直接返回
	let target = Array.isArray(source) ? [] : {} //数组兼容
	for (var k in source) {
		if (source.hasOwnProperty(k)) {
			if (typeof source[k] === 'object') {
				target[k] = deepClone(source[k])
			} else {
				target[k] = source[k]
			}
		}
	}
	return target
}
export const formatDate = (date, middle = '-', hasTime = true) => {
	date = new Date(date)
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()

	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()

	const t1 = [year, month, day].map(formatNumber).join(middle)
	const t2 = [hour, minute, second].map(formatNumber).join(':')

	return hasTime ? `${t1} ${t2}` : t1
}
export const formatArrToObject = (arr, key, value) => {
	let obj = {}
	if (Array.isArray(arr) && arr.length) {
		arr.forEach(el => {
			obj[el[key]] = el[value]
		})
	}
	return obj
}

export const getQueryVariable = (variable) => {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return (false);
}

//检测是否需要更新
export const isUpdate = (curVersion, serVersion) => { // 判断是否需要更新
	let [serArray, curArray] = [serVersion.split(".").join(''), curVersion.split(".").join('')]
	return Number(curArray) < Number(serArray)
	// if (parseInt(serArray[0]) > parseInt(curArray[0])) {
	// 	return true
	// } else if (parseInt(serArray[1]) > parseInt(curArray[1])) {
	// 	return true
	// } else if (parseInt(serArray[1]) >= parseInt(curArray[1]) && parseInt(serArray[2]) > parseInt(curArray[
	// 		2])) {
	// 	return true
	// } else {
	// 	return false
	// }
}

//更新

export const doUpData = (downloadApkUrl) => {
	
	// var downloadApkUrl = that.downloadApkUrl;
	console.log(downloadApkUrl)
	var dtask = plus.downloader.createDownload(downloadApkUrl, {}, function(d, status) {
		// 下载完成  
		if (status == 200) {
			console.log("下载完成", d, downloadApkUrl, d.filename)
			plus.runtime.install(plus.io.convertLocalFileSystemURL(d.filename), {}, function(e) {
				console.log("plus.runtime.install success", e);
				plus.runtime.restart();
			}, function(error) {
				uni.showModal({
					title: '升级失败',
					content: error.message,
					showCancel: false,
				});
			})
		} else {
			console.log("下载失败", d)
			uni.showModal({
				title: '升级失败',
				content: "升级包下载未完成, 请稍候重试",
				showCancel: false,
			});
		}
	});
	//开始下载
	dtask.start();
	var prg = 0;
	var showLoading = plus.nativeUI.showWaiting("正在下载"); //创建一个showWaiting对象
	// 进度条
	dtask.addEventListener('statechanged', function(
		task,
		status
	) {
		// 给下载任务设置一个监听 并根据状态  做操作
		switch (task.state) {
			case 1:
				showLoading.setTitle("正在下载");
				break;
			case 2:
				showLoading.setTitle("已连接到服务器");
				break;
			case 3:
				prg = parseInt(
					(parseFloat(task.downloadedSize) /
						parseFloat(task.totalSize)) *
					100
				);
				showLoading.setTitle("  正在下载" + prg + "%  ");
				break;
			case 4:
				plus.nativeUI.closeWaiting();
				//下载完成
				break;
		}
	})
}

export const isPC = () => {
	// #ifdef WEB
	const platform = navigator.platform;
	return (platform.includes('Win') || platform.includes('Mac'));
	// #endif
	return false;
}