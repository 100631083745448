import App from './App'
import { createSSRApp, getCurrentInstance } from 'vue'
import Vuex from "vuex";
// import integralDel from "./pages/component/jifenkouchu.vue"
// import modalVideo from "./pages/component/modalVideo.vue"
// Vue.component('integralDel',integralDel)
// Vue.component('modalVideo',modalVideo)

import request from "./utils/request.js"
// Vue.config.productionTip = false
import { toast, message, success, preViewImg} from "./utils/plugin"
// import { checkLogin } from './utils/index.js'
import ApiGloabal from "./utils/api.js"
import jwx from "./utils/jwx.js"
import store from './store'
// #ifdef H5
// import vconsole from "vconsole"
// new vconsole()
// #endif
// import { VueJsonp } from 'vue-jsonp'
// Vue.use(VueJsonp)
import helper from "./utils/helper";

// 不能修改导出的 createApp 方法名，不能修改从 Vue 中导入的 createSSRApp。
export function createApp() {
  const app = createSSRApp(App)

  app.config.globalProperties.$api = ApiGloabal;
  app.config.globalProperties.request = request;
  app.config.globalProperties.$jwx = jwx;
  app.config.globalProperties.$helper = helper;

  uni.getSystemInfo({
      success: function(e) {
          // Store.state.StatusBar = e.statusBarHeight
          // #ifdef APP-PLUS
          app.config.globalProperties.isAndroid = e.platform == 'android'
          app.config.globalProperties.isIos = e.platform == 'ios'
          app.config.globalProperties.nowPlatform = e.platform
          app.config.globalProperties.nowSystem = Number(e.system.substring(4, 6))
          // #endif

          // console.log("systemInfo ", e)
          // #ifndef MP

          if (e.platform == 'android') {
              app.config.globalProperties.CustomBar = e.statusBarHeight + 50
          } else {
              app.config.globalProperties.CustomBar = e.statusBarHeight + 45
          }

          // that.globalData.nowSystem = Number(e.system.substring(4, 6)) // 版本号
          // that.globalData.nowPlatform = e.platform // 系统
          // #endif

          // #ifdef MP-WEIXIN
          app.config.globalProperties.StatusBar = e.statusBarHeight
          let custom = wx.getMenuButtonBoundingClientRect()
          app.config.globalProperties.Custom = custom
          app.config.globalProperties.CustomBar = custom.bottom + custom.top - e.statusBarHeight
          // #endif

          // #ifdef MP-ALIPAY
          app.config.globalProperties.StatusBar = e.statusBarHeight
          app.config.globalProperties.CustomBar = e.statusBarHeight + e.titleBarHeight
          // #endif
      }
  })
  // app.config.globalProperties.$jwx = jwx
  // app.config.globalProperties.$checkLogin = checkLogin
  app.use(store).use(toast).use(message).use(success).use(preViewImg)
  return {
      app,
	  Vuex,
  }
}

// Vue 3 转 Vue 2, 在 main.js 中写入以下代码即可
function isPromise(obj) {
  return (
    !!obj &&
    (typeof obj === "object" || typeof obj === "function") &&
    typeof obj.then === "function"
  );
}

uni.addInterceptor({
  returnValue(res) {
    if (!isPromise(res)) {
      return res;
    }
    const returnValue = [undefined, undefined];
    return res
      .then((res) => {
        returnValue[1] = res;
      })
      .catch((err) => {
        returnValue[0] = err;
      })
      .then(() => returnValue);
  },
});
// createApp()

// Vue.prototype.$store = store
// Vue.prototype.$checkLogin = checkLogin

// Vue.use(toast).use(message).use(success).use(preViewImg)

// const app = new Vue({
// 	store,
//     ...App
// })
// app.$mount()





