<script>
	import {
		watch
	} from 'vue'
	import Store from './store'

	import {
		setStorage
	} from '@/service/storage'
	import {
		IosAppId,
		updateText
	} from '@/utils/config.js'
	import {
		onLaunch
	} from '@dcloudio/uni-app'
	import {
		isUpdate,
		doUpData
	} from "./utils";
	import {
		useGetGlobalProperties
	} from '@/utils/hooks.js'

	const App = getApp({
		allowDefault: true
	})
	export default {
		setup() {
			// const store = useStore()
			const global = useGetGlobalProperties()

			/**
			 * 拦截跳转 是否pc端 开发环境下不执行
			 */
			function interceptor() {
				// #ifdef H5
				if (process.env.NODE_ENV == 'development') return void 0
				const platform = navigator.platform
				const page = window.location.href
				// 协议页面
				if (page.match('agreement') || page.match('warning')) return
				if (platform.includes('Win') || platform.includes('Mac')) {
					if (!page.match('login1')) {
						uni.reLaunch({
							url: '/pages/warning/index'
						})
					}
				}
				// 拦截一下uni.reLaunch 这个方法的跳转
				// 统一跳转到警告页
				uni.addInterceptor('reLaunch', {
					invoke(args) {
						if ((platform.includes('Win') || platform.includes('Mac')) && args.url !==
							'pages/warning/index' && !args.url.match('agreement')) {
							args.url = '/pages/warning/index'
						}
					}
				})
				// #endif
			}

			onLaunch(options => {
				// #ifndef H5-UAT
				// interceptor()
				// #endif

				if (['pages/index/index', 'pages/master/master'].includes(options.path)) {
					checkDiffrentCity()

					// #ifdef APP-PLUS
					console.log("俺要检查")
					// getVersion()
					// #endif
				}
				GetThreeCity()
			})
			// console.log(global.CustomBar)

			const checkDiffrentCity = async () => {
				if (Store.state.myCityInfo && Store.state.myCityInfo.id) {
					if (global.isAndroid) {
						// const hasLocation = getStorage('hasLoation')
						// console.log("是否具有权限：",hasLocation)
						// if (hasLocation == -1 || hasLocation == 0) {
						// 	console.log("安卓已经拒绝授权不再提示授权地理位置信息!!!!!!!!!!")
						// 	return
						// }
					}

					uni.getLocation({
						type: 'wgs84',
						success: async res => {
							Store.state.longitude = res.longitude
							Store.state.latitude = res.latitude
							setStorage('longitude', res.longitude)
							setStorage('latitude', res.latitude)
							let locationStr = res.latitude + ',' + res.longitude
							const [err, res1] = await global.$api.searchCity({
								location: locationStr
							})

							if (err) return
							if (res1.code == 10000) {
								if (res1.data.name && (res1.data.name !== Store.state.myCityInfo
									.name)) {
									uni.showModal({
										content: `您当前定位在${res1.data.name},是否切换到${
                                        res1.data.name
                                    }?`,
										title: '提示',
										success: con => {
											if (con.confirm) {
												Store.commit('setCityInfo', res1.data)
												uni.reLaunch({
													url: '/pages/index/index'
												})
											}
										},
										fail: erra => {
											uni.showToast({
												title: erra,
												icon: 'none'
											})
										}
									})
								}
							}
						},
						fail: function(err) {
							console.log(err)
						}
					})
				}
			}

			const GetThreeCity = async () => {
				// const myCityInfo = uni.getStorageSync('myCityInfo')
				// if(myCityInfo) return
				const [err, res] = await global.$api.GetThreeCity()
				if (res.code != 10000) {
					return global.$toast('获取城市异常')
				}
				//原始数组
				Store.state.cityList = res.data
			}

			return {
				// globalData,
				checkDiffrentCity,
				GetThreeCity
			}
		}
	}
</script>

<style lang="less">
	page {
		background-color: #f7f7f7;
	}

	@import 'static/css/index.less';
	@import 'App.css';
    @import 'static/iconfont/iconfont.css';
</style>
