export const toast = {
    install: (app) => {
        app.config.globalProperties.$toast = (message) => {
            uni.showToast({
                title: message,
                icon: 'none',
                duration: 2500
            })
        }
    }
}

export const message = {
    install: (app) => {
        app.config.globalProperties.$message = (message) => {
            uni.showModal({
            	title: "提示",
				content: message,
				showCancel: false
            })
        }
    }
}

export const success = {
    install: (app) => {
        app.config.globalProperties.$success = (message = '提交成功') => {
            uni.showToast({
            	title: message,
				duration: 2500
            })
        }
    }
}

export const toLogin = {
    install: (app) => {
        app.config.globalProperties.$toLogin = () => {
            uni.navigateTo({
                 url: '/pages/login/login'
            });
        }
    }
}

export const preViewImg = {
    install: (app) => {
        app.config.globalProperties.$preViewImg = (imgUrl = '') => {
			const arr = [imgUrl]
            uni.previewImage({
            	count:1,
				urls:arr
            })
        }
    }
}
