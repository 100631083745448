// let baseImgUrl = process.env.NODE_ENV === 'production' ? "https://gf.cleair-cn.com" : "https://https://gf.cleair-cn.com"
let ApiUrl = process.env.NODE_ENV == 'production' ? "https://admin.xizizhijia.com" : "http://localhost:90"
let appid1 = 'wx935eb43e42e5c8cf'
let onLineUrl1 = "https://m.xizizhijia.com"
// #ifdef H5-UAT
	console.warn("注意当前是测试UAT环境！！！！！！不能直接发布到正式环境！")
	ApiUrl = 'http://admin.xizizhijia.com'
	appid1 = 'wx935eb43e42e5c8cf'
	onLineUrl1 = 'https://m.xizizhijia.com'
// #endif

// #ifdef STAGE
console.warn("注意当前是测试STAGE环境！！！！！！不能直接发布到正式环境！")
ApiUrl = 'http://test.xizizhijia.com'
appid1 = 'wx935eb43e42e5c8cf'
onLineUrl1 = 'http://m_test.xizizhijia.com'
// #endif

export const onLineUrl = onLineUrl1
export const Appid = appid1
export const baseApiUrl = ApiUrl


// 企业微信id
export const companyWxAppid = 'wwe6a2fcd8c0c04f30'
// 白天客服
export const baitianKF = 'https://work.weixin.qq.com/kfid/kfcd0481d04abc6d880'
// 夜晚客服
export const yewanKF = 'https://work.weixin.qq.com/kfid/kfc946b259cc238e9e3'


export const IosAppId = 6472132031
// 微信开放平台id
export const wxOpenAppid = 'wx5a683d09be307a6c'

// 客服电话
export const phoneNumber = ""
// if (process.env.NODE_ENV === 'development') {
//     // TODO
// }
// // uEnvProd
// if (process.env.NODE_ENV === 'production') {
//     // TODO
// }
console.log(process.env)
export const callPhone = () => {
    uni.makePhoneCall({
        phoneNumber
    });
}
export const updateText = '新版本已准备好,可随时更新...'

export const FRAGMENT_ID_SPEED_RULE_USER = 22
