// #ifdef H5
// var jweixin = require('jweixin-module');
import jweixin from "weixin-js-sdk";
// #endif
import ApiGloabal from "./api.js"; // 微信支付配置
export default {
  GetInfoMation() {
    return new Promise((resolve, reject) => {
      const data = {
        url: location.href.split("#")[0],
      };
      console.log(location.href.split("#")[0]);
      ApiGloabal.payConfig(data)
        .then((res) => {
          // console.log("res1",res[1].data);
          // 转换为javascript对象
          let configure = res[1];
          // console.log("appid",configure.data.appId);
          console.log("参数", configure.data);
          if (configure.code == 10000) {
            jweixin.config({
              debug: false,
              appId: configure.data.appId,
              timestamp: configure.data.timestamp,
              nonceStr: configure.data.nonceStr,
              signature: configure.data.signature,
              jsApiList: [
                "chooseWXPay",
                "updateTimelineShareData",
                "updateAppMessageShareData",
              ],
            });
            jweixin.ready(function (res) {
              console.log("成功", res);
            });
            jweixin.error(function (res) {
              console.log("失败", res);
            });
            resolve();
          } else {
			uni.showToast({
				title: res.Message || "数据出错",
				icon: 'none'
				
			})
            reject();
          }
        })
        .catch((e) => {
          console.log("e", e);
          // this.$toast("网络故障");
        });
    });
  },
  setShareConfig({ title, link, desc, imgUrl }) {
    jweixin.ready(function (res) {
      jweixin.checkJsApi({
        jsApiList: [
          "chooseWXPay",
          "updateTimelineShareData",
          "updateAppMessageShareData",
        ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          console.log("checkJsApi ", res);
          if (res.checkResult.updateAppMessageShareData) {
            jweixin.updateAppMessageShareData({
              title, // 分享标题
              desc, // 分享描述
              link, // 分享链接
              // imgUrl: imgUrl, // 分享图标
              imgUrl, //分享的图片
              success: (e) => {
                console.log("success", e);
                // uni.showModal({
                // 	content: '分享成功',
                // 	showCancel: false
                // })
              },
              fail: (e) => {
                console.log("fail", e);
                // uni.showModal({
                // 	content: e.errMsg,
                // 	showCancel: false
                // })
              },
            });
          } else {
            console.log("无权限分享");
          }
		  
		  if (res.checkResult.updateTimelineShareData) {
		    jweixin.updateTimelineShareData({
		      title, // 分享标题
		      desc, // 分享描述
		      link, // 分享链接
		      // imgUrl: imgUrl, // 分享图标
		      imgUrl, //分享的图片
		      success: (e) => {
		        console.log("success", e);
		        // uni.showModal({
		        // 	content: '分享成功',
		        // 	showCancel: false
		        // })
		      },
		      fail: (e) => {
		        console.log("fail", e);
		        // uni.showModal({
		        // 	content: e.errMsg,
		        // 	showCancel: false
		        // })
		      },
		    });
		  } else {
		    console.log("无权限分享");
		  }

          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
      });
    });
  },

  wechatPay(params){
	  
	  // #ifdef H5
	  return new Promise((resolve, reject) => {
		  try{
			if (WeixinJSBridge) {
				WeixinJSBridge.invoke(
					'getBrandWCPayRequest', {
						"appId": params.appId, //公众号名称，由商户传入
						"timeStamp":params.timeStamp, //时间戳     
						"nonceStr":params.nonceStr, //随机串     
						"package": params.package,
						"signType":params.signType, //微信签名方式：     
						"paySign": params.paySign //微信签名 
					},
					function(ress) {
						if (ress.err_msg == "get_brand_wcpay_request:ok") {
							resolve(true)
						} else if (ress.err_msg == "get_brand_wcpay_request:cancel") {
							resolve(false)
							uni.showToast({
								icon: "none",
								title: "已取消支付"
							})
						} else {
							resolve(false)
							uni.showToast({
								icon: "none",
								title: "支付失败"
							})
						}
					}
				);
			} else {
				jweixin.chooseWXPay({
					timestamp: params.timeStamp,
					nonceStr: params.nonceStr,
					package: params.package,
					signType: params.signType,
					paySign: params.paySign,
					success: (res) => {
						resolve(true)
					},
					fail: (e) => {
						resolve(false)
						uni.showToast({
							icon: "none",
							title: "支付失败"
						})
					},
				});
			}
		
		  }catch(e){
		  	//TODO handle the exception
			reject(e)
		  }
		  
	  })
	 
	  // #endif
  },
  //地图

  // 调用微信支付接口
  // async pay() {
  //   // console.log(this.$route.query.index ==1);
  //   console.log("开始支付");
  //   const user = JSON.parse(localStorage.getItem("userInfo"))
  //   console.log("user",user);
  //   const payInfo = {
  //     user_id:user.id,
  //     openid:user.openid
  //   };
  //   let that = this;
  //   await Recharge(payInfo).then((res) => {
  //     jweixin.chooseWXPay({
  //       timestamp: res.timeStamp,
  //       nonceStr: res.nonceStr,
  //       package: res.package,
  //       signType: res.signType,
  //       paySign: res.paySign,
  //       success: () => {
  //         console.log("支付成功");
  //         // console.log("跳转页面");
  //           that.$router.push({ path: "/my" });
  //         that.$toast.success({
  //           message: "支付成功",
  //           forbidClick: true,
  //           duration: 1000,
  //         });
  //       },
  //       fail: (e) => {
  //         that.$toast.fail({
  //           message: "支付失败",
  //           forbidClick: true,
  //           duration: 1000,
  //         });
  //       },
  //     });
  //   });
  //   // console.log(res);
  // },
};
