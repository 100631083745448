import {FRAGMENT_ID_SPEED_RULE_USER} from "./config";

export default {
    navigateTo(url = '/', offset = 0) {
        uni.navigateTo({
            url: url,
            offset: offset,
        })
    },
    // 打开碎片
    openFragmentPage(id) {
        this.navigateTo(`/pages/my/fragment?id=${id}`);
    },
    openFragmentSpeedRuleUser () {
        this.openFragmentPage(FRAGMENT_ID_SPEED_RULE_USER);
    },
}
